var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'modal': true,
  'is-active': _vm.active
}},[_c('div',{staticClass:"modal-background",on:{"click":function($event){return _vm.$emit('cancel')}}}),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"box"},[_c('h1',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('people.change_password_for'))+" "+_vm._s(_vm.person.name)+" ")]),_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('text-field',{ref:"first-password",attrs:{"disabled":_vm.person.is_generated_from_ldap,"label":_vm.$t('people.fields.password'),"type":"password"},on:{"enter":function($event){return _vm.confirmClicked()}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('text-field',{attrs:{"disabled":_vm.person.is_generated_from_ldap,"label":_vm.$t('people.fields.password_2'),"type":"password"},on:{"enter":function($event){return _vm.confirmClicked()}},model:{value:(_vm.form.password2),callback:function ($$v) {_vm.$set(_vm.form, "password2", $$v)},expression:"form.password2"}})],1),_c('div',{staticClass:"flexrow"},[_c('button',{class:{
            button: true,
            'is-primary': true,
            'flexrow-item': true,
            'is-loading': _vm.isLoading
          },attrs:{"disabled":_vm.person.is_generated_from_ldap},on:{"click":_vm.confirmClicked}},[_vm._v(" "+_vm._s(_vm.$t('profile.change_password.button'))+" ")]),_c('button',{class:{
            button: true,
            'flexrow-item': true,
            'is-loading': _vm.isLoading,
            'is-warning': true
          },attrs:{"disabled":!(_vm.person.totp_enabled || _vm.person.email_otp_enabled)},on:{"click":_vm.disableTwoFactorAuthenticationClicked}},[_vm._v(" "+_vm._s(_vm.$t('people.disable_2FA'))+" ")]),_c('div',{staticClass:"filler"}),_c('button',{staticClass:"button is-link flexrow-item",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("main.cancel"))+" ")])]),(!_vm.isValid)?_c('div',{staticClass:"error has-text-right mt1"},[_vm._v(" "+_vm._s(_vm.$t('profile.change_password.unvalid'))+" ")]):_vm._e(),(_vm.isError)?_c('div',{staticClass:"error has-text-right mt1"},[_vm._v(" "+_vm._s(_vm.$t('people.change_password_error'))+" ")]):_vm._e(),(_vm.isErrorDisableTwoFactorAuthentication)?_c('div',{staticClass:"error has-text-right mt1"},[_vm._v(" "+_vm._s(_vm.$t('people.disable_2FA_error'))+" ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }